<template>
  <div class="container">
    <el-descriptions class="margin-top" title="订单信息" :column="3" border>
      <el-descriptions-item label="订单号">
        {{ Order.flowing_no }}
      </el-descriptions-item>
      <el-descriptions-item label="下单时间">
        {{ Order.rcharge_time }}
      </el-descriptions-item>
      <el-descriptions-item label="状态">
        <p
          v-if="Order.reverse_status"
          :style="{ color: revese_status[Order.reverse_status].color }"
        >
          {{ revese_status[Order.reverse_status].name }}
        </p>
      </el-descriptions-item>
      <el-descriptions-item label="商品名称">
        <p v-if="Order.rcharge_type == 'consume'">消费金</p>
        <p v-else-if="Order.rcharge_type == 'coupon'">优惠券</p>
        <p v-else-if="Order.rcharge_type == 'point'">权益点</p>
        <p v-else>{{ Order.title }}</p>
      </el-descriptions-item>
      <el-descriptions-item label="支付金额">
        <p v-if="Order.pay_type == 'point'">{{ Order.amount }}权益点</p>
        <p v-else>{{ Order.amount }}</p>
      </el-descriptions-item>
      <el-descriptions-item label="支付方式">
        <p v-if="Order.pay_type == 'wx'">微信</p>
        <p v-if="Order.pay_type == 'ali'">支付宝</p>
        <p v-if="Order.pay_type == 'point'">权益点</p>
      </el-descriptions-item>
      <el-descriptions-item label="订单来源">
        <p v-if="Order.source == 'sport'">小程序</p>
        <p v-else>{{ Order.source }}</p>
      </el-descriptions-item>
      <el-descriptions-item label="数量">
        {{ Order.course_num }}
      </el-descriptions-item>
      <el-descriptions-item label="支付时间">
        {{ Order.finished_time }}
      </el-descriptions-item>
      <el-descriptions-item label="说明">
        {{ Order.rcharge_abstract }}
      </el-descriptions-item>
    </el-descriptions>

    <el-descriptions class="margin-top" title="课程信息" :column="3" border v-if="needInfo.id">
      <el-descriptions-item label="联系人">
        {{ needInfo.name }}
      </el-descriptions-item>
      <el-descriptions-item label="联系电话">
        {{ needInfo.phone }}
      </el-descriptions-item>
      <el-descriptions-item label="上课地址">
        {{ needInfo.city }}-{{ needInfo.address }}
      </el-descriptions-item>
      <el-descriptions-item label="类目">
        {{ needInfo.categories }}
      </el-descriptions-item>
      <el-descriptions-item label="课节">
        {{ needInfo.course_num }}
      </el-descriptions-item>
      <el-descriptions-item label="教练">
        {{ needInfo.coach_name }}
      </el-descriptions-item>
    </el-descriptions>


 <el-descriptions class="margin-top" title="上课情况" :column="3" border>
  <el-descriptions-item label="剩余课节数">
      {{ userStudyCard.course_num }}
      </el-descriptions-item>
      <el-descriptions-item label="总课节数">
        {{ userStudyCard.original_course_num }}
      </el-descriptions-item>
      <el-descriptions-item label="体验课节数">
        {{ userStudyCard.settlement_course }}
      </el-descriptions-item>
   </el-descriptions>

    <div style="margin-top: 20px">
      <el-descriptions
        class="margin-top"
        title="申请售后信息"
        :column="3"
        border
      >
        <template
          slot="extra"
          v-if="ReveseOrder.type == '换课' && ReveseOrder.status == 2"
        >
          <el-button type="warning" size="small" @click="openConfrimChange"
            >同意换课</el-button
          >
          <el-button type="danger" size="small" @click="dialogVisible = true"
            >拒绝换课</el-button
          >
        </template>
        <template
          slot="extra"
          v-else-if="
            ReveseOrder.type == 'need_bond_refund' && ReveseOrder.status == 2
          "
        >
          <el-button type="warning" size="small" @click="openConfiremNeedRefund"
            >同意退款</el-button
          >
          <el-button type="danger" size="small" @click="dialogVisible = true"
            >拒绝退款</el-button
          >
        </template>
        <template slot="extra" v-else-if="ReveseOrder.status == 2">
          <el-button type="warning" size="small" @click="openConfiremRefund"
            >同意退款</el-button
          >
          <el-button type="danger" size="small" @click="dialogVisible = true"
            >拒绝退款</el-button
          >
        </template>
        <el-descriptions-item label="售后类型">
          {{ saleAfter_type[ReveseOrder.type] || ReveseOrder.type }}
        </el-descriptions-item>
        <el-descriptions-item label="可退金额">
          {{ ReveseOrder.amount }}
        </el-descriptions-item>
        <el-descriptions-item label="可退权益点">
          {{ ReveseOrder.point }}
        </el-descriptions-item>
        <el-descriptions-item label="申请理由">
          {{ ReveseOrder.reason }}
        </el-descriptions-item>
        <el-descriptions-item label="申请售后时间">
          {{ ReveseOrder.create_time }}
        </el-descriptions-item>
        <el-descriptions-item label="拒绝理由">
          {{ ReveseOrder.refuse_contect }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div
      style="margin-top: 20px"
      v-if="ReveseOrder.status == 3 && ReveseOrder.type == '换课'"
    >
      <el-descriptions
        class="margin-top"
        title="换课订单信息"
        :column="3"
        border
        v-if="newOrder.id"
      >
        <el-descriptions-item label="课程名称">
          {{ newOrder.title }}
        </el-descriptions-item>
        <el-descriptions-item label="课程节数">
          {{ newOrder.course_num }}
        </el-descriptions-item>
        <el-descriptions-item label="课程价格">
          {{ newOrder.amount_payable }}
        </el-descriptions-item>
        <el-descriptions-item label="线下收款金额">
          {{ newOrder.offline_collection }}
        </el-descriptions-item>
        <el-descriptions-item label="线下收款图片">
          <el-image
            v-if="newOrder.offline_collection_img"
            style="width: 100px; height: 100px"
            :src="newOrder.offline_collection_img"
            :preview-src-list="[newOrder.offline_collection_img]"
          ></el-image>
        </el-descriptions-item>
        <el-descriptions-item label="平台补贴金额">
          {{ newOrder.platform_subsidy }}
        </el-descriptions-item>
        <el-descriptions-item label="换课支付金额">
          {{ newOrder.amount }}
        </el-descriptions-item>
        <el-descriptions-item label="订单生成时间">
          {{ newOrder.rcharge_time }}
        </el-descriptions-item>
      </el-descriptions>
      <div v-else>已同意换课申请，等待用户同意换课</div>
    </div>

    <el-dialog title="拒绝售后" :visible.sync="dialogVisible" width="40%">
      <el-form>
        <el-form-item label="拒绝原因" required>
          <el-input
            type="textarea"
            rows="4"
            v-model="reson"
            placeholder="请输入拒绝原因"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="refuseRefund">确 定</el-button>
      </span>
    </el-dialog>
    <!--  -->
    <el-dialog title="同意换课" :visible.sync="changeVisible" width="40%">
      <el-descriptions class="margin-top" title="原课程" :column="1">
        <el-descriptions-item label="课程名称">
          {{ Order.title || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="剩余课时数">
          {{ Order.course_num || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="课程剩余课时费">
          {{
            Order.pay_type == "point"
              ? ReveseOrder.point * 10
              : ReveseOrder.amount
          }}
        </el-descriptions-item>
      </el-descriptions>
      <el-descriptions class="margin-top" title="更换课程" :column="1">
        <template slot="extra">
          <el-button type="warning" size="small" @click="toChangeCourse"
            >选择课程</el-button
          >
        </template>
        <el-descriptions-item label="课程名称">
          {{ courseInfo.title || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="课时数">
          {{ courseInfo.course_num || "" }}
        </el-descriptions-item>
        <el-descriptions-item label="价格">
          {{ courseInfo.amount || "" }}
        </el-descriptions-item>
      </el-descriptions>
      <el-form>
        <el-form-item label="线下支付金额">
          <el-input
            v-model="offline_collection"
            placeholder="请输入线下支付金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="线下收款图片">
          <avatar-uploader @getImgUrl="getpic" :url="pic"></avatar-uploader>
        </el-form-item>
        <el-form-item label="平台优惠金额">
          <el-input
            v-model="platform_subsidy"
            placeholder="请输入优惠金额"
          ></el-input>
        </el-form-item>
        <hr />
        <el-form-item>
          <span style="font-size: 16px; font-weight: 600">
            {{ price > 0 ? "退款：" : "支付：" }}{{ Math.abs(price) }}
          </span>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="changeVisible = false">取 消</el-button>
        <el-button type="primary" @click="confrimChangeCourse">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 同意需求退款 -->
    <el-dialog title="需求保证金退款" :visible.sync="needShow" width="40%">
      <el-form>
        <el-form-item label="用户可退保证金">
          {{ ReveseOrder.amount }}
        </el-form-item>
        <el-form-item label="无忧担保扣款">
          <el-radio v-model="needForm.deduct_type" label="none"
            >不扣款</el-radio
          >
          <el-radio v-model="needForm.deduct_type" label="deduct_user"
            >补偿教练(扣用户)</el-radio
          >
          <el-radio v-model="needForm.deduct_type" label="deduct_coach"
            >补偿用户(扣教练)</el-radio
          >
        </el-form-item>
        <el-form-item label="扣除金额">
          <el-input
            v-model="needForm.deduct_amount"
            placeholder="请输入扣除金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="说明">
          <el-input
            type="textarea"
            rows="4"
            v-model="needForm.deduct_remark"
            placeholder="请输入说明"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="dialog-footer">
        <el-button @click="needShow = false">取 消</el-button>
        <el-button type="primary" @click="agreeNeedRefund">退 款</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      changedialogVisible: false,
      changeVisible: false,
      reson: "",
      id: "",
      oid: "",
      price_off: 0,
      platform_subsidy: 0, //优惠
      ReveseOrder: {},
      Order: {},
      courseInfo: {},
      cid: "",
      pic: "",
      offline_collection: 0,
      changeOrder: {},
      newOrder: {},
      saleAfter_type: {
        退课: "退课",
        换课: "换课",
        coupon_refund: "优惠券退款",
        consume_refund: "消费金退款",
        venue_refund: "场馆退款",
        need_bond_refund: "需求保证金退款",
      },
      revese_status: {
        0: { name: "未申请售后", color: "#f0ad4e" },
        1: { name: "退款中", color: "#f0ad4e" },
        2: { name: "换课中", color: "#f0ad4e" },
        3: { name: "已退款", color: "#FC7819" },
        4: { name: "拒绝退款", color: "#fb3a13" },
        5: { name: "已换课", color: "#FC7819" },
        6: { name: "拒绝换课", color: "#fb3a13" },
        7: { name: "退款取消", color: "#fb3a13" },
        8: { name: "换课取消", color: "#fb3a13" },
      },
      needShow: false,
      needInfo: {},
      needForm: {
        deduct_type: "none",
      },
      userStudyCard:{}
    };
  },
  computed: {
    // 总价
    price() {
      let originPrice =
        this.Order.pay_type == "point"
          ? this.ReveseOrder.point * 10
          : this.ReveseOrder.amount;
      let newPrice = this.courseInfo.amount;
      return (
        originPrice +
        Number(this.platform_subsidy) +
        Number(this.offline_collection) -
        newPrice
      );
    },
  },
  mounted() {
    this.id = this.$route.query.id;
    this.oid = this.$route.query.oid;
    this.cid = this.$route.query.cid;
    if (this.cid) {
      this.courseInfo.title = this.$route.query.title;
      this.courseInfo.course_num = this.$route.query.course_num;
      this.courseInfo.amount = this.$route.query.amount;
      this.changeVisible = true;
    }
    if (this.id) {
      this.getOrderInfo();
      this.getReveseOrderInfo();
    } else {
      this.getOrderInfo();
      this.getReveseOrder();
    }
  },

  methods: {
    getStudyCard() {
      if(this.Order.user_study_card_id){
        this.$axios({
          url: "/user/userStudyCard/findById",
          params: {
            id: this.Order.user_study_card_id,
          },
        }).then((res) => {
          this.userStudyCard = res.data.data;
        });
      }
    },


    //需求保证金退款
    agreeNeedRefund() {
      let data = {
        id: this.id,
        status: 3,
        coach_id: this.needInfo.coach_id,
      };
      if (this.needForm.deduct_type != "none") {
        data.deduct_type = this.needForm.deduct_type;
        data.deduct_amount = this.needForm.deduct_amount;
        data.deduct_reamrk = this.needForm.deduct_reamrk;
        if (!this.needForm.deduct_amount || !this.needForm.deduct_remark) {
          this.$message("请填写扣款信息");
          return;
        }
        if (this.needForm.deduct_amount > this.ReveseOrder.amount) {
          this.$message("扣款金额不能大于退款");
          return;
        }
      }
      if (this.needForm.deduct_type == "deduct_user") {
        data.deduct_user_id = this.Order.user_id;
      }
      if (this.needForm.deduct_type == "deduct_coach") {
        data.subsidy_user_id = this.Order.user_id;
      }
      this.$axios({
        url: "/user/rechargerecordrefund/update",
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message("退款成功");
          this.needShow = false;
          this.getReveseOrderInfo();
        } else {
          this.$message(res.data.message);
        }
      });
    },
    getNeedInfo() {
      this.$axios({
        url: "/user/userCourseNeed/findById",
        params: {
          id: this.Order.studycard_id,
        },
      }).then((res) => {
        this.needInfo = res.data.data;
      });
    },
    openConfiremNeedRefund() {
      this.needShow = true;
    },
    // 售后详情
    getReveseOrder() {
      this.$axios({
        url: "/user/rechargerecordrefund/queryListPage",
        params: {
          recharge_record_id: this.oid,
        },
      }).then((res) => {
        this.ReveseOrder = res.data.data[0];
        this.id = this.ReveseOrder.id;
      });
    },
    getNewOrderInfo(id) {
      this.$axios({
        url: "/user/rechargerecord/queryListPage",
        params: {
          id,
        },
      }).then((res) => {
        this.newOrder = res.data.data[0];
      });
    },
    toChangeCourse() {
      this.$router.push({
        name: "mecCouList",
        query: {
          type: "change",
          id: this.id,
          oid: this.oid,
        },
      });
    },
    openConfiremRefund() {
      this.$confirm(
        `确认退款${this.Order.title},退款金额：${this.ReveseOrder.amount} ${
          this.ReveseOrder.point
            ? ",退款权益点：" + this.ReveseOrder.point + "权益点。"
            : ""
        }`,
        "退款确认"
      )
        .then((res) => {
          this.confrimRefund();
        })
        .catch(() => {
          console.log("cancle");
        });
    },
    openConfrimChange() {
      this.changeVisible = true;
    },
    // 同意换课
    confrimChangeCourse() {
      let data = {
        id: this.id,
        status: 3,
        study_card_id: this.cid,
        platform_subsidy: this.platform_subsidy || 0,
      };
      if (this.offline_collection > 0) {
        if (this.pic == "") {
          this.$message("请上传收款凭证");
          return;
        } else {
          data.offline_collection = this.offline_collection;
          data.offline_collection_img = this.pic;
        }
      }
      this.$axios({
        url: "/user/rechargerecordrefund/update",
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message("换课成功");
          this.changeVisible = false;
          this.getReveseOrderInfo();
        } else {
          this.$message(res.data.message);
        }
      });
    },
    // 拒绝
    refuseRefund() {
      if (this.reson == "") {
        this.$message("请输入拒绝理由");
        return;
      }
      let data = {
        id: this.id,
        status: 4,
        refuse_contect: this.reson,
      };
      this.$axios({
        url: "/user/rechargerecordrefund/update",
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message("拒绝成功");
          this.dialogVisible = false;
          this.getOrderInfo();
          this.getReveseOrderInfo();
        } else {
          this.$message(res.data.message);
        }
      });
    },
    // 同意退款
    confrimRefund() {
      let data = {
        id: this.id,
        status: 3,
      };
      this.$axios({
        url: "/user/rechargerecordrefund/update",
        data,
        method: "post",
      }).then((res) => {
        if (res.data.code == 0) {
          this.$message("退款成功");
          this.getOrderInfo();
          this.getReveseOrderInfo();
        }
      });
    },
    getpic(v) {
      this.pic = v;
    },
    // 订单详情
    getOrderInfo() {
      this.$axios({
        url: "/user/rechargerecord/findById",
        params: {
          id: this.oid,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.Order = res.data.data;
          this.getStudyCard()
          if (this.Order.new_order_id > 0) {
            this.getNewOrderInfo(this.Order.new_order_id);
          }
          if (this.Order.rcharge_type == "need_bond") {
            this.getNeedInfo();
          }
        }
      });
    },
    // 售后详情
    getReveseOrderInfo() {
      this.$axios({
        url: "/user/rechargerecordrefund/findById",
        params: {
          id: this.id,
        },
      }).then((res) => {
        if (res.data.code == 0) {
          this.ReveseOrder = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped></style>